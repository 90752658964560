











































import { Component, Vue, Watch } from 'vue-property-decorator'
import Page from '@/components/Page/index.vue'
import Status from '@/utils/Status'
import api from '../../api'
import ItemSimple from '../../components/ItemSimple/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'

@Component({ components: { Page, StatusFlash, ItemSimple } })
export default class ApiKeys extends Vue {
  status: Status = new Status()

  apiKeys: any = null

  created() {
    this.fetchData()
  }

  @Watch('$route')
  async fetchData() {
    try {
      this.status.setPending()
      const response = await api.apiKeys.getApiKeys()
      this.apiKeys = response.data
      this.status.setDone()
    } catch (error) {
      console.error(error)
      this.status.setError('Unable to get API keys.')
    }
  }

  async deleteApiKey(apiKey) {
    if (window.confirm('Are you sure you want to delete the API key?')) {
      try {
        await api.apiKeys.deleteApiKey(apiKey)
        this.fetchData()
      } catch (error) {
        console.error(error)
        this.status.setError('Unable to delete API key.')
      }
    }
  }

  async generateApiKey() {
    try {
      this.status.setPending()
      await api.apiKeys.postApiKey({})
      this.status.setDone()
      await this.fetchData()
    } catch (error) {
      console.error(error)
      this.status.setError('Unable to generate API key.')
    }
  }
}
