


































































































































import _ from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import moment from 'moment'
import api from '@/api'
import Page from '@/components/Page/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import config from '@/config'
import Status from '@/utils/Status'
import StatusFlash from '@/components/StatusFlash/index.vue'
import { stateClass } from '@/utils/fdpIndex'

@Component({ components: { Page, Pagination, StatusFlash } })
export default class Index extends Vue {
  data: any = null

  info: any = null

  filter: string = 'ACTIVE'

  sort: string = 'modificationTime,desc'

  page: number = 1

  status: Status = new Status()

  actionStatus: Status = new Status()

  states: string[] = ['ALL', 'ACTIVE', 'INACTIVE', 'UNREACHABLE', 'INVALID', 'UNKNOWN']

  get user() {
    return this.$store.getters['auth/user']
  }

  get isAdmin() {
    return _.get(this.user, 'role') === 'ADMIN'
  }

  created(): void {
    this.init()
  }

  sortUrl(sort: string): string {
    return `/?state=${this.filter}&sort=${sort}`
  }

  filterUrl(filter: string): string {
    return `/?state=${filter}&sort=${this.sort}`
  }

  formatDateTime(value): string {
    return moment(value).format(config.dateTimeFormat)
  }

  stateToReadable(state) {
    return _.startCase(_.toLower(state))
  }

  badgeClass(state) {
    return stateClass(state)
  }

  openPage(page) {
    this.$router.push(`/?state=${this.filter}&sort=${this.sort}&page=${page + 1}`)
  }

  @Watch('$route')
  async init() {
    this.filter = _.get(this.$route.query, 'state', this.filter) as string
    this.sort = _.get(this.$route.query, 'sort', this.sort) as string
    this.page = parseInt(_.get(this.$route.query, 'page', this.page) as string, 10)

    try {
      this.status.setPending()
      this.data = null

      const requests = axios.all([
        api.fdpIndex.getEntries({ state: this.filter, sort: this.sort, page: this.page }),
        api.fdpIndex.getInfo(),
      ])

      const [dataResponse, infoResponse] = await requests

      this.data = dataResponse.data
      this.info = infoResponse.data

      this.status.setDone()
    } catch (error) {
      console.error(error)
      this.status.setError('Unable to get FAIR Data Points.')
    }
  }

  async syncFdp(entry) {
    try {
      this.actionStatus.setPending()
      await api.fdpIndex.ping(entry.clientUrl)
      await this.init()
      this.actionStatus.setDone(`Syncing of "${entry.clientUrl}" started!`)
    } catch (error) {
      console.error(error)
      this.actionStatus.setError(`Unable to sync "${entry.clientUrl}"`)
    }
  }

  async removeFdp(entry) {
    if (window.confirm(`Are you sure you want to remove "${entry.clientUrl}"?`)) {
      try {
        this.actionStatus.setPending()
        await api.fdpIndex.deleteEntry(entry.uuid)
        await this.init()
        this.actionStatus.setDone(`Successfully removed "${entry.clientUrl}"`)
      } catch (error) {
        console.error(error)
        this.actionStatus.setError(`Unable to remove "${entry.clientUrl}"`)
      }
    }
  }
}
