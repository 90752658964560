








































































































import _ from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PrismEditor from 'vue-prism-editor'
import Page from '@/components/Page/index.vue'
import Status from '@/utils/Status'
import api from '../../api'
import ItemSimple from '../../components/ItemSimple/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'


@Component({ components: { Page, PrismEditor, StatusFlash } })
export default class ShapesImport extends Vue {
  loadingStatus: Status = new Status()

  importStatus: Status = new Status()

  fdpUrl: string = null

  shapes: any = null

  showDefinition = []

  get fdpUrlEmpty() {
    return _.isEmpty(this.fdpUrl)
  }

  get someShapeSelected() {
    return this.shapes && this.shapes.some(shape => shape.selected)
  }

  isDefinitionVisible(uuid) {
    return this.showDefinition.some(u => u === uuid)
  }

  showShape(uuid) {
    this.showDefinition.push(uuid)
  }

  hideShape(uuid) {
    this.showDefinition = this.showDefinition.filter(u => u !== uuid)
  }

  async loadShapes() {
    if (!this.fdpUrlEmpty) {
      try {
        this.loadingStatus.setPending()
        const response = await api.shapes.getImport(this.fdpUrl)
        this.shapes = response.data
        this.loadingStatus.setDone()
      } catch (error) {
        console.error(error)
        this.loadingStatus.setError(`Unable to retrieve shapes from ${this.fdpUrl}`)
      }
    }
  }

  async importShapes() {
    if (this.someShapeSelected) {
      try {
        this.importStatus.setPending()
        const shapes = this.shapes.filter(shape => shape.selected)
        await api.shapes.postImport(shapes)
        this.importStatus.setDone('Shapes were successfully imported')
      } catch (error) {
        console.error(error)
        this.importStatus.setError('Unable to import shapes')
      }
    }
  }
}
