












































































































































































































































































































































































































import { required, url } from 'vuelidate/lib/validators'
import axios from 'axios'
import _ from 'lodash'
import config from '@/config'
import api from '../../api'
import Breadcrumbs from '../../components/Breadcrumbs/index.vue'
import Page from '../../components/Page/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'
import Status from '../../utils/Status'

export default {
  name: 'ResourceDefinitionCreate',
  components: {
    Breadcrumbs,
    Page,
    StatusFlash,
  },

  data() {
    return {
      errors: [],
      resourceDefinition: {
        name: null,
        urlPrefix: null,
        shapeUuids: [],
        children: [],
        externalLinks: [],
      },
      status: new Status(),
      submitStatus: new Status(),
      resourceOptions: [],
      breadcrumbs: [{
        label: 'Resource definitions',
        to: '/resource-definitions',
      }],
      currentResourceDefinition: null,
    }
  },

  computed: {
    isEdit() {
      return !!this.$route.params.uuid
    },

    title() {
      return this.currentResourceDefinition
        ? `Edit ${this.currentResourceDefinition.name}`
        : 'Create resource definition'
    },
  },

  validations() {
    return {
      resourceDefinition: {
        name: { required },
        urlPrefix: {
          uniqueness: (value) => {
            const error = this.errors.find(e => e.field === 'urlPrefix')
            const code = _.get(error, 'code')
            const rejectedValue = _.get(error, 'rejectedValue')
            return !(code === 'Uniqueness' && rejectedValue === value)
          },
        },
        shapeUuids: {
          required,
          $each: {
            uuid: { required },
          },
        },
        children: {
          $each: {
            resourceDefinitionUuid: { required },
            relationUri: { required, url },
            listView: {
              title: { required },
              tagsUri: { url },
              metadata: {
                $each: {
                  title: { required },
                  propertyUri: { required, url },
                },
              },
            },
          },
        },
        externalLinks: {
          $each: {
            title: { required },
            propertyUri: { required, url },
          },
        },
      },
    }
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      try {
        this.status.setPending()
        const [resourceDefinitions, shapes] = await this.loadData()

        this.resourceOptions = _.orderBy(resourceDefinitions.data, ['name'], ['asc'])
          .map(resource => ({ key: resource.uuid, value: resource.name }))
        this.resourceOptions.unshift({ key: null, value: '- select -' })

        this.shapeOptions = _.orderBy(shapes.data, ['name'], ['asc'])
          .map(shape => ({ key: shape.uuid, value: shape.name }))
        this.shapeOptions.unshift({ key: null, value: '- select -' })

        if (this.isEdit) {
          const resourceDefinition = _.first(
            resourceDefinitions.data.filter(r => r.uuid === this.$route.params.uuid),
          )
          if (resourceDefinition) {
            this.resourceDefinition = this.requestDataToFormData(resourceDefinition)
            this.currentResourceDefinition = resourceDefinition
          }
        }

        this.status.setDone()
      } catch (error) {
        console.error(error)
        this.status.setError('Unable to get shapes')
      }
    },

    async loadData() {
      return axios.all([
        api.resourceDefinition.getResourceDefinitions(),
        api.shapes.getShapes(),
      ])
    },

    async submit() {
      this.$v.resourceDefinition.$touch()

      if (!this.$v.resourceDefinition.$invalid) {
        this.submitStatus.setPending()
        this.errors = []
        try {
          const request = this.isEdit
            ? api.resourceDefinition.putResourceDefinition
            : api.resourceDefinition.postResourceDefinition
          await request(this.formDataToRequestData(this.resourceDefinition))
          // full reload is necessary after changing resource definitions
          window.location.href = `${config.publicPath}/resource-definitions`
        } catch (error) {
          console.error(error)
          const errors = _.get(error, 'response.data.errors')

          if (errors) {
            this.submitStatus.setError('Unable to save resource definition')
            this.errors = errors
          } else {
            this.submitStatus.setErrorFromResponse(error, 'Resource definition could not be saved.')
          }
          window.scrollTo(0, 0)
        }
      }
    },

    formDataToRequestData(formData) {
      const data = { ...formData }
      data.shapeUuids = _.uniq(formData.shapeUuids.map(u => u.uuid))
      return data
    },

    requestDataToFormData(requestData) {
      const formData = { ...requestData }
      formData.shapeUuids = requestData.shapeUuids.map(uuid => ({ uuid }))
      return formData
    },

    addChild() {
      this.resourceDefinition.children.push({
        resourceDefinitionUuid: null,
        relationUri: null,
        listView: {
          title: null,
          tagsUri: null,
          metadata: [],
        },
      })
    },

    removeChild(index) {
      this.resourceDefinition.children.splice(index, 1)
    },

    addShapeUuid() {
      this.resourceDefinition.shapeUuids.push({ uuid: null })
    },

    removeShapeUuid(index) {
      this.resourceDefinition.shapeUuids.splice(index, 1)
    },

    addMetadata(childIndex) {
      this.resourceDefinition.children[childIndex].listView.metadata.push({
        title: null,
        propertyUri: null,
      })
    },

    removeMetadata(childIndex, index) {
      this.resourceDefinition.children[childIndex].listView.metadata.splice(index, 1)
    },

    addExternalLink() {
      this.resourceDefinition.externalLinks.push({ title: null, propertyUri: null })
    },

    removeExternalLink(index) {
      this.resourceDefinition.externalLinks.splice(index, 1)
    },
  },
}
