












































































































































import { required } from 'vuelidate/lib/validators'
import PrismEditor from 'vue-prism-editor'
import FormRenderer from '@/components/ShaclForm/FormRenderer.vue'
import { SHACLFormParser } from '@/components/ShaclForm/Parser/SHACLFormParser'
import api from '../../api'
import Breadcrumbs from '../../components/Breadcrumbs/index.vue'
import Page from '../../components/Page/index.vue'
import Status from '../../utils/Status'
import StatusFlash from '../../components/StatusFlash/index.vue'

export default {
  name: 'ShaclDetail',
  components: {
    Breadcrumbs,
    FormRenderer,
    StatusFlash,
    Page,
    PrismEditor,
  },

  validations() {
    return {
      shape: {
        name: { required },
        definition: { required },
        published: { required },
      },
    }
  },

  data() {
    return {
      title: null,
      shape: null,
      internal: false,
      shapeForm: {
        name: null,
        definition: null,
        published: false,
      },
      status: new Status(),
      submitStatus: new Status(),
      breadcrumbs: [{
        label: 'Shapes',
        to: '/shapes',
      }],
      preview: false,
      form: null,
      previewError: null,
      data: { subject: null, data: {} },
    }
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      try {
        this.status.setPending()

        const response = await api.shapes.getShape(this.$route.params.id)
        this.shape = response.data
        this.internal = this.shape.type === 'INTERNAL'
        this.setTitle()
        this.status.setDone()
      } catch (error) {
        console.error(error)
        this.status.setError('Unable to get shape.')
      }
    },

    async submit() {
      this.$v.shape.$touch()

      if (!this.$v.shape.$invalid) {
        try {
          this.submitStatus.setPending()
          await api.shapes.putShape(this.shape)
          this.setTitle()
          this.submitStatus.setDone('Shape was successfully updated!')
        } catch (error) {
          this.submitStatus.setErrorFromResponse(error, 'Shape could not be updated.')
        }
      }
    },

    setTitle() {
      this.title = this.shape.name
    },

    showPreview(preview) {
      if (preview) {
        try {
          this.previewError = null
          this.data = { subject: null, data: {} }

          const parser = new SHACLFormParser(this.shape.definition)
          this.form = parser.parseAll()
        } catch (error) {
          this.previewError = error
        }
      }
      this.preview = preview
    },
  },
}
