









































































































import { Component } from 'vue-property-decorator'
import axios from 'axios'
import _ from 'lodash'
import Breadcrumbs from '@/components/Breadcrumbs/index.vue'
import EntityMetadata from '@/components/EntityMetadata/index.vue'
import ItemList from '@/components/ItemList/index.vue'
import MembershipBadge from '@/components/MembershipBadge/index.vue'
import Page from '@/components/Page/index.vue'
import StatusFlash from '@/components/StatusFlash/index.vue'
import metadata from '@/utils/metadata'
import permissions from '@/utils/permissions'
import { parseSHACLView } from '@/components/ShaclForm/Parser/SHACLViewParser'
import EntityBase from '@/components/EntityBase'


@Component({
  components: {
    Breadcrumbs,
    EntityMetadata,
    ItemList,
    MembershipBadge,
    Page,
    StatusFlash,
  },
})
export default class EntityView extends EntityBase {
  createLink: string = null

  extraLinks: any[] = []

  itemLists: any = null

  activeItemList: any = null

  meta: any = null

  metadata: any = null

  shape: any = null

  get permissions() {
    return permissions
  }

  get canCreateChild() {
    return this.config.hasChildren
      && (this.isAdmin || this.config.canCreateChild(this.isAuthenticated, this.meta))
  }

  get isDraft() {
    return _.get(this.meta, 'state.current') === 'DRAFT'
  }

  get title() {
    return this.isDraft ? `[DRAFT] ${this.entity.title}` : this.entity.title
  }

  actionEnabled(action: string): boolean {
    return _.includes(this.config.viewActions, action)
  }

  actionUrl(action: string): string {
    const { path } = this.$route
    return _.endsWith('/', path) ? `${path}${action}` : `${path}/${action}`
  }

  setActiveItemList(itemList) {
    this.activeItemList = itemList
  }

  reset() {
    this.metadata = null
    this.itemLists = null
    this.activeItemList = null
    this.meta = null
    this.extraLinks = []
    this.createLink = null
    this.shape = null
  }

  async fetchData(): Promise<void> {
    try {
      this.status.setPending()
      const [entity, spec, meta] = await this.loadData()

      this.buildGraph(entity.data)
      this.shape = parseSHACLView(spec.data, this.config.targetClasses)
      this.meta = meta.data
      this.metadata = this.createMetadata()
      this.extraLinks = this.config.getLinks(this.graph)
      this.breadcrumbs = this.config.createBreadcrumbs(this.meta.path, this.subject)

      if (this.config.hasChildren) {
        this.itemLists = this.config.createChildrenLists(this.canCreateChild, this.entityId)
        this.activeItemList = _.first(this.itemLists)
      }

      this.status.setDone()
    } catch (error) {
      console.error(error)
      this.status.setErrorFromResponse(error, 'Unable to get data.')
    }
  }

  async loadData() {
    return axios.all([
      this.config.api.get(this.entityId),
      this.config.api.getSpec(),
      this.config.api.getMeta(this.entityId),
    ])
  }

  createMetadata() {
    return [
      ...metadata.commonMetadata(this.graph),
      ...this.createLocalMetadata(),
      metadata.rdfLinks(this.subject),
    ]
  }

  createLocalMetadata() {
    return this.shape.fields
      .map(field => metadata.fromShaclField(this.graph, field))
      .filter(field => field !== null)
  }

  async deleteEntity() {
    if (window.confirm(`Are you sure you want to delete ${this.entity.title}?`)) {
      try {
        await this.config.api.delete(this.entityId)
        const parent = _.get(_.last(this.breadcrumbs), 'to', '/')
        await this.$router.push(parent)
      } catch (error) {
        console.error(error)
        this.status.setError('Unable to delete data.')
      }
    }
  }

  async publishEntity() {
    if (window.confirm(`Are you sure you want to publish ${this.entity.title}?`)) {
      try {
        this.status.setPending()
        await this.config.api.putMetaState(this.entityId, { current: 'PUBLISHED' })
        await this.fetchData()
      } catch (error) {
        console.error(error)
        this.status.setError('Unable to publish data.')
      }
    }
  }
}
