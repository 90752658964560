





















































import { Component, Vue, Watch } from 'vue-property-decorator'
import api from '@/api'
import rdfUtils from '@/rdf/utils'
import Page from '@/components/Page/index.vue'
import Status from '@/utils/Status'
import StatusFlash from '@/components/StatusFlash/index.vue'

@Component({ components: { Page, StatusFlash } })
export default class SearchResults extends Vue {
  query: string = null

  status: Status = new Status()

  results: any = null

  created(): void {
    this.init()
  }

  pathTerm(term): string {
    return rdfUtils.pathTerm(term)
  }

  @Watch('$route')
  async init() {
    this.query = this.$route.query.q as string

    try {
      this.status.setPending()
      this.results = null
      const response = await api.search.search({ q: this.query })
      this.results = response.data
      this.status.setDone()
    } catch (error) {
      console.error(error)
      this.status.setError('Unable to get search results')
    }
  }
}
